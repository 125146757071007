<template>
<div id="contact" class="contact-wrapper">
  <div style="visibility: hidden;">a&nbsp;</div>
  <div style="visibility: hidden;">a&nbsp;</div>

  <h1 class="title">Para maiores informações</h1>Fale com os nossos representantes

<div class="contact columns">
    <div class="column">
      <div class="contact-text">
        <h1 class="rep">Gabriel Migues</h1>gabriel.migues@jmsreis.com.br<br>(21) 99760-2863
      </div>
    </div>
    <div class="column">
      <div class="contact-text">
        <h1 class="rep">Maurício Reis</h1>contato@jmsreis.com.br<br>(21) 96454-2554
      </div>
    </div>
    </div>
  </div>
  
</template>


<script>
export default {
  name: "Contact",
};
</script> 

<style scoped>

.contact-wrapper .contact {
  margin-top: 50px;
}

.contact-wrapper {
  margin-top: 70px;
  padding: 35px;
}
.contact-wrapper .title {
  display: block;
  font-size: 50px;
}

.contact-wrapper .rep{ 
font-weight: bold;
font-size: 20px;
padding-top: 50px;
}
</style>
