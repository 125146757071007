<template>
  <div class="footer-wrapper">
    <div class="my-footer">
      Copyright © 2021 JMS Representações | Todos os direitos reservados. JMS
      Reis 401 Representações. CNPJ: 12.095.508/0001-08
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style @scoped>
.my-footer {
  padding-top: 10px;
  margin-top: 20px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #bfbfbf;
}

.footer-wrapper {
  width: 100%;

  padding: 5vw;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
