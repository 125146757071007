<template>
  <div class="parallax">
 <h1 class="title">Nossa missão é conectar</h1><h1 class="title-1"> o cliente ao seu negócio</h1>

  </div>
</template>

<script>
export default {
  name: "Body",
};
</script>

<style scoped>
.parallax {
  /* The image used */
  background-image: url("/background.png");

  /* Set a specific height */
  min-height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax .title {
  display: block;
  font-size: 80px;
  color: white;
  text-shadow: 2px 2px 10px black;
  padding-top: 35vh;
}
.parallax .title-1 {
  display: block;
  font-size: 80px;
  color: white;
  text-shadow: 2px 2px 10px black;
}
</style>