<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand topbar columns" v-if="!mobile">

      <div class="logo-wrapper column">
        <a class="navbar-item" href="#">
          <img src="icons/logo.png" class="logo" />
        </a>
      </div>
 
      <div class="item-wrapper column">
        <a class="navbar-item item" href="#journal">
          Quem Somos
        </a>
        <a class="navbar-item item" href="#services">
          Serviços
        </a>
        <a class="navbar-item item" href="#representadas">
          Representadas
        </a>
        <a class="navbar-item item" href="#contact">
          Contato
        </a>
      </div> 

      <div class="icon-wrapper column">
        <a
          target="_blank"
          href="https://www.instagram.com/jmsreis_representacoes/"
        >
          <img class="topbar-icon" src="icons/instagram.png" />
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/jms-reis">
          <img class="topbar-icon" src="icons/linkedin.png" />
        </a>
      </div>
    </div>
    <div v-else>
      <div class="columns is-mobile">
        <div class="logo-wrapper column">
          <a class="navbar-item" href="#">
            <img src="icons/logo-mobile-branco.png" class="logo-mobile-wrapper" />
          </a>
        </div>

        <div class="logo-wrapper column">
          <a v-on:click="onBurgerClick" class="navbar-item" href="#">
            <img src="icons/menu.png" class="logo-mobile-wrapper" />
          </a>
        </div>
      </div>
      <div v-bind:class="{ hidden: menuHidden }" class="sidebar">
        <a class="sidebar-item" href="#journal" v-on:click="hiddenSidebar">
          Quem Somos
        </a>
        <a class="sidebar-item" href="#services" v-on:click="hiddenSidebar">
          Serviços
        </a>
        <a class="sidebar-item" href="#representadas" v-on:click="hiddenSidebar">
          Representadas
        </a>
        <a class="sidebar-item" href="#contact" v-on:click="hiddenSidebar">
          Contato
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TopBar",
  data: function() {
    return {mobile: false, menuHidden: true}
  },
  mounted: function() {
    this.onResize()
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize: function() {
      if(window.innerWidth < 1000) {
        this.mobile = true
      } else {
        this.mobile = false
      }
    },
    onBurgerClick: function() {
      this.menuHidden = !this.menuHidden
    },
    hiddenSidebar: function() {
      this.menuHidden = true;
    }
  }
};
</script>

<style @scoped>
.hidden {
  display: none
}

.topbar {
  padding: 0;
  margin: 12;
  height: 100%;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
}

.topbar-icon {
  width: 18px;
  height: 18px;
  color: white;
  margin-left: 20px;
}

.logo {
  height: 60px;
}

.logo-mobile-wrapper {
  height: 12vw;
  max-height: 12vw !important;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  background-color: #0075cc !important;
  color: gray;
  
}

.logo-wrapper .navbar-item {
  margin: auto;
  display: block;
}

/*
.icon-wrapper {
  position: absolute;
  top: 20px;
  right: 30px;
}

*/

.navbar .item-wrapper .navbar-item {
  margin: 0;
  padding: 0;
  margin-left: 0px;
  margin-right: 50px;
}

.navbar .item {
  font-size: 15px;
  color: white;
}

.navbar .item:hover {
  color: white;
  text-decoration: underline;
}

.navbar .icon-wrapper {
  padding-top: 30px;
  padding-left: 50px;
}

.navbar .item-wrapper {
  display: inherit;
  padding-top: 17px;
}

.navbar .logo-wrapper {
  display: inherit;
  padding-top: 17px;
}


.navbar {
  width: 100%;
  height: 100%;
  padding: 0px;
  background-color: #0075cc !important;
}

.sidebar {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #0075cc;
  z-index: 1;
  padding-bottom: 60%;
}

.sidebar-item {
  margin-top: 10px;
  display: block;
  font-size: 70px;
  color: white;
}
</style>