<template>
  <div id="representadas" class="parallax-separator">
    <div style="visibility: hidden;">a&nbsp;</div>
  <div style="visibility: hidden;">a&nbsp;</div>

  <h1 class="title">Representadas</h1>

    <div class="columns logos-wrapper is-desktop">
      <div class="column">
        <div class="logo-wrapper">
          <a
          target="_blank"
          href="http://www.syllent.com.br/">

          <img class="rep-1" src="logos/syllent.png">
          </a>
        </div>
        </div>
        <div class="column">
        <div class="logo-wrapper">
          <a
          target="_blank"
          href="https://www.ksb.com/ksb-br-pt/">
        
          <img class="rep-2" src="logos/ksb logo.png">
          </a>
        </div>
        </div>
        <div class="column">
        <div class="logo-wrapper">
          <a
          target="_blank"
          href="https://maresias.ind.br/">

          <img class="rep-3" src="logos/maresias_logo.jpg">
          </a>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Separator",
};
</script>

<style scoped>
.parallax-separator {
  /* The image used */
  background-image: url("/separator.png");

  /* Set a specific height */
  min-height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo-wrapper {
  width: 250px;
  height: 150px;
  background-color: white;
  padding: 15px;
  box-shadow: 2px 2px 10px black; 

  margin: auto;
}



.parallax-separator .title {
  display: block;
  font-size: 80px;
  color: white;
  text-shadow: 2px 2px 10px black;
  padding-top: 50px;
}

/*.parallax-separator .columns {
 padding: 250px;
 padding-left: 420px;

}*/

.logos-wrapper {
  padding-top: 200px;
}

.rep-1{
  padding: 10px;
  padding-top: 15%;

}
.rep-2{
  padding: 10px;
  padding-top: 5%;

}
.rep-3{
  padding: 10px;
  padding-top: 15%;

}
</style>