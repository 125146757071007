<template>
<div id="journal" class="journal-wrapper">
  <div style="visibility: hidden;">a&nbsp;</div>
  <div style="visibility: hidden;">a&nbsp;</div>

  <h1 class="title">Quem Somos</h1>

  <div class="journal columns">
    <div class="column" style="background-color: blue;"></div>
    <div class="column">
      <h2 class="hist-title">O que é JMS?</h2>
      <span class="text">
        A JMS é uma empresa de reperesentação comercial, que atua no mercado a mais de 10 anos.<br>
        Temos uma equipe de profissionais focada em entregar e resultos, buscando sempre encontrar<br>
        soluções para atender nossas representadas e parceiros.
      </span>
    </div>
    </div>

<div class="MVV">
    <div class="journal columns is-desktop">
      <div class="column">
      <h2 class="hist-title">Missão</h2>
      <span class="text">
        Trabalhamos todos os dias para conectar pessoas e negócios.
      </span>
    </div>
    <div class="column">
      <h2 class="hist-title">Visão</h2>
      <span class="text">
        Buscamos promover um hambiente de negócios favoravel<br>
        para o crescimento dos nossos parceiros comerciais.
      </span>
    </div>
    <div class="column">
      <h2 class="hist-title">Valores</h2>
      <span class="text">
        O que nos move são os desafios, por isso, valozamos pessoas<br>
        com brio e que buscam se aprimorar todos os dias.

      </span>
    </div>
    </div>
  </div>
  </div>
</template>


<script>
export default {
  name: "Journal",
};
</script> 

<style scoped>

.journal-wrapper .journal {
  margin-top: 50px;
}

.journal-wrapper {
  margin-top: 70px;
  padding: 35px;
}
.journal-wrapper .title {
  display: block;
  font-size: 80px;
}


.journal-wrapper .hist-title {
  font-size: 50px;
}

.journal-wrapper .MVV{
  padding-top: 130px;
  padding-bottom: 50px;
}

.text {
  font-size: 30px;
}
</style>
