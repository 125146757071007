<template>
  <form class="form" method="post" action="submit.php">
    <div class="field">
      <label class="label">Nome</label>
      <div class="control">
        <input class="input" type="text" placeholder="Seu Nome..." name="name" />
      </div>
    </div>
    <div class="field">
      <label class="label">Sobrenome</label>
      <div class="control">
        <input class="input" type="text" placeholder="Seu Sobrenome..." name="surname"/>
      </div>
    </div>
    <div class="field">
      <label class="label">Empresa</label>
      <div class="control">
        <input class="input" type="text" placeholder="Sua Empresa..." name="company"/>
      </div>
    </div>
    <div class="field">
      <label class="label">Telefone</label>
      <div class="control">
        <input class="input" type="text" placeholder="(XX) XXXX-XXXX" name="phone"/>
      </div>
    </div>
    <div class="field">
      <label class="label">E-mail</label>
      <div class="control">
        <input class="input" type="text" placeholder="seu E-mail..." name="email"/>
      </div>
    </div>

    <div class="field">
      <label class="label">Mensagem</label>
      <div class="control">
        <textarea class="textarea" placeholder="Sua Mensagem..." name="message"></textarea>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link">Enviar</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Form",
};
</script>

<style @scoped>
.form {
  padding-left: 5vw;
  padding-right: 5vw;
  text-align: left;
  margin-top: 100px;
}
</style>