<template>
  <div id="app">
    <div class="top-bar-wrapper">
      <top-bar />
    </div>
    <Body />
    <Journal />
    <Services/>
    <Separator />
    <Contact />
    <Form />
    <Footer />
  </div>
</template>

<script>
import TopBar from "./components/topbar.vue";
import Body from "./components/body.vue";
import Journal from "./components/journal.vue";
import Form from "./components/form.vue";
import Footer from "./components/footer.vue";
import Contact from "./components/contact.vue";
import Separator from "./components/separator.vue";
import Services from "./components/services.vue";

export default {
  name: "App",
  components: {
    Services,
    TopBar,
    Body,
    Journal,
    Form,
    Footer,
    Contact,
    Separator,
  },
};
</script>

<style>
#app {
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;  
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0px;

  height: 100%;
}

html,
body {
  height: 100%;
}

.top-bar-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
</style>
